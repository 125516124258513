<template>
	<div class="register">
		<div class="box-card">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="用户注册" name="first">
					<el-form v-if="activeName == 'first'" :model="registerForm" :rules="registerRules" ref="registerRef"
						label-width="100px" label-position="right" show-message>

						<el-form-item label="企业类型" prop="activeselect">
							<el-select v-model="registerForm.activeselect" value-key="label_id" placeholder="请选择"
								@change="sellectoption">
								<el-option v-for="item in registerForm.radioData" :key="item.label_id"
									:label="item.label_name" :value="item">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="登录账号" prop="username">
							<el-input v-model="registerForm.username" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input v-model="registerForm.password" placeholder="请输入密码" type="password"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="checkPass">
							<el-input v-model="registerForm.checkPass" placeholder="请输入确认密码" type="password"></el-input>
						</el-form-item>
						<el-form-item label="选择上级销售" prop="inputfenxiao_name">
<!--							<el-input v-model="registerForm.inputfenxiao_name" placeholder="请输入上级销售姓名或电话"-->
<!--							@input="fenxiaonamechange" @blur="inputseekshow = false"></el-input>-->
<!--							<div v-if="inputseekshow" style="position: absolute; z-index: 999" >-->
<!--								<span class="insek_list" v-for="(item,index) in inputseeklist" :key="index" @click.native.stop="cityChange(item)">{{item.name}}</span>-->
<!--							</div>-->
							<el-select v-model="registerForm.inputfenxiao_name"  filterable remote placeholder="请输入上级销售姓名"
								:remote-method="fenxiaonamechange" style="width: 840px;" >
								<el-option v-for="(item, index) in inputseeklist" @click.native.stop="cityChange(item)" :key="index" :label="item.username"
									:value="item.username"></el-option>
							</el-select>
                            <div><span>{{registerForm.fenxiaoname}}</span></div>
						</el-form-item>
						<el-form-item label="单位名称" prop="nickname">
							<el-input v-model="registerForm.nickname" placeholder="请输入单位名称"></el-input>
						</el-form-item>
						<el-form-item label="联系人" prop="realname">
							<el-input v-model="registerForm.realname" placeholder="请输入联系人"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="mobile">
							<el-input v-model="registerForm.mobile" placeholder="请输入联系电话"></el-input>
						</el-form-item>
						<el-form-item label="邀请码" prop="invitation_code">
							<el-input v-model="registerForm.invitation_code" placeholder="请输入邀请码"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="captcha_code">
							<el-input v-model="registerForm.captcha_code" placeholder="请输入验证码" maxlength="4">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCode" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="收货地址" prop="full_address">
							<el-select v-model="registerForm.province_id" placeholder="请选择省"
								@change="changeProvice(registerForm.province_id)">
								<el-option v-for="option in province" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name
									}}</el-option>
							</el-select>
							<el-select v-model="registerForm.city_id" placeholder="请选择市"
								@change="changeCity(registerForm.city_id)">
								<el-option v-for="option in city" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name
									}}</el-option>
							</el-select>
							<el-select v-model="registerForm.district_id" placeholder="请选择区/县"
								@change="changeDistrict(registerForm.district_id)">
								<el-option v-for="option in district" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name
									}}</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" prop="full_address">
							<el-input v-model="registerForm.full_address" :disabled="true"
								class="ns-len-input"></el-input>
						</el-form-item>
						<el-form-item label="详细地址" prop="address">
							<el-input v-model="registerForm.address"   placeholder="请输入详细地址"
							 ></el-input>
						</el-form-item>
					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
							<div class="content">
								阅读并同意
								<b @click.stop="getAggrement">《服务协议》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">已有账号，立即登录</div>
					</div>
					<el-button @click="register">立即注册</el-button>
				</el-tab-pane>

				<!-- <el-tab-pane label="手机动态码注册" name="second" v-if="registerConfig.register.indexOf('mobile') != -1">
					<el-form v-if="activeName == 'second'" :model="registerForm" :rules="mobileRules" ref="mobileRuleForm">
						<el-form-item prop="mobile">
							<el-input v-model="registerForm.mobile" placeholder="请输入手机号">
								<template slot="prepend">
									<i class="iconfont icon-shouji-copy"></i>
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="vercode">
							<el-input v-model="registerForm.vercode" autocomplete="off" placeholder="请输入验证码" maxlength="4">
								<template slot="prepend">
									<i class="iconfont icon-yanzhengma"></i>
								</template>
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCode" />
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="dynacode">
							<el-input v-model="registerForm.dynacode" maxlength="4" placeholder="请输入短信动态码">
								<template slot="prepend">
									<i class="iconfont icon-dongtaima"></i>
								</template>
								<template slot="append">
									<div class="dynacode" :class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'" @click="sendMobileCode('mobileRuleForm')">
										{{ dynacodeData.codeText }}
									</div>
								</template>
							</el-input>
						</el-form-item>


					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
							<div class="content">
								阅读并同意
								<b @click.stop="getAggrement">《服务协议》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">已有账号，立即登录</div>
					</div>

					<el-button @click="registerMobile">立即注册</el-button>
				</el-tab-pane> -->
			</el-tabs>
			<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%"
				:before-close="aggrementClose" :lock-scroll="false" center>
				<div v-html="agreement.content" class="xyContent"></div>
			</el-dialog>
		</div>
		<!-- 浮层区 -->
		<div class="floatLayer-wrap" v-show="is_show && reward" :style="{ width: bgWidth, height: bgHeight }">
			<div class="reward-wrap">
				<img :src="$util.img('upload/uniapp/register_reward_img.png')" mode="widthFix"
					class="bg-img-head"></img>
				<img :src="$util.img('upload/uniapp/register_reward_money.png')" mode="widthFix"
					class="bg-img-money"></img>
				<img :src="$util.img('upload/uniapp/register_reward_head.png')" mode="widthFix" class="bg-img"></img>
				<div class="wrap">
					<div>
						<div class="reward-content">
							<div class="reward-item" v-if="reward && reward.point > 0">
								<div class="head">积分奖励</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.point }}</span>
											<span class="type">积分</span>
										</div>
										<div class="desc">用于下单时抵现或兑换商品等</div>
									</div>
									<div class="tip" @click="closeRewardPopup('point')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.growth > 0">
								<div class="head">成长值</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.growth }}</span>
											<span class="type">成长值</span>
										</div>
										<div class="desc">用于提升客户等级</div>
									</div>
									<div class="tip" @click="closeRewardPopup('growth')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.balance > 0">
								<div class="head">红包奖励</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.balance }}</span>
											<span class="type">元</span>
										</div>
										<div class="desc">不可提现下单时可用</div>
									</div>
									<div class="tip" @click="closeRewardPopup('balance')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.coupon_list.length > 0">
								<div class="head">优惠券奖励</div>
								<div class="content" v-for="(item, index) in reward.coupon_list" :key="index">
									<div class="info">
										<div>
											<span class="num coupon-name">{{ item.coupon_name }}</span>
										</div>
										<div class="desc" v-if="item.at_least > 0">
											满{{ item.at_least }}{{ item.type == 'discount' ? '打' + item.discount + '折' :
												'减' +
											item.money }}
										</div>
										<div class="desc" v-else>
											无门槛，{{ item.type == 'discount' ? '打' + item.discount + '折' : '减' +
												item.money }}
										</div>
									</div>
									<div class="tip" @click="closeRewardPopup('coupon')">立即查看</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="close-btn" @click="closeRewardPopup()">
					<i class="iconfont icon-guanbi"></i>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {
	getRegisiterAggrement,
	registerConfig,
	registerMobileCode,
	getRegisterReward,
	register,
	registerlabellist,
	getFenxiaoname
} from '@/api/auth/register';
import {
	captcha
} from '@/api/website';
import {
	getArea
} from "@/api/address"
export default {
	name: 'register',
	components: {},
	// watch: {
	// 	registerForm: {
	// 		handler() {
	// 			if (this.registerForm.province_id && this.registerForm.city_id && this.registerForm.district_id) this.registerForm.address = 1
	// 		}, deep: true
	// 	}
	// },
	data() {
		var checkPassValidata = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.registerForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		let self = this;
		var passwordValidata = function (rule, value, callback) {
			let regConfig = self.registerConfig;
			if (!value) {
				return callback(new Error('请输入密码'));
			} else {
				if (regConfig.pwd_len > 0) {
					if (value.length < regConfig.pwd_len) {
						return callback(new Error('密码长度不能小于' + regConfig.pwd_len + '位'));
					} else {
						callback();
					}
				} else {
					if (regConfig.pwd_complexity != '') {
						let passwordErrorMsg = '密码需包含',
							reg = '';
						if (regConfig.pwd_complexity.indexOf('number') != -1) {
							reg += '(?=.*?[0-9])';
							passwordErrorMsg += '数字';
						} else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
							reg += '(?=.*?[a-z])';
							passwordErrorMsg += '、小写字母';
						} else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
							reg += '(?=.*?[A-Z])';
							passwordErrorMsg += '、大写字母';
						} else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
							reg += '(?=.*?[#?!@$%^&*-])';
							passwordErrorMsg += '、特殊字符';
						} else {
							reg += '';
							passwordErrorMsg += '';
						}

						if (reg.test(value)) {
							return callback(new Error(passwordErrorMsg));
						} else {
							callback();
						}
					}
				}
			}
		};
		var isMobile = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("手机号不能为空"))
			} else {
				const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

				if (reg.test(value)) {
					callback()
				} else {
					callback(new Error("请输入正确的手机号"))
				}
			}
		};
		return {
			fileList: [],
			imageUrl: '',
			registerForm: {
				username: '',
				password: '',
				checkPass: '',
				mobile: '',
				vercode: '',
				dynacode: '',
				key: '',
				nickname: '',
				realname: '',
				tel: '',
				captcha_code: '',
				province_id: '',
				city_id: '',
				district_id: '',
				full_address: '',
				member_label_name: '',
				member_label: '',
				radioData: [],
				activeselect: '',
				address: '',
				parent_id:0,
				invitation_code: "", //邀请码
				inputfenxiao_name: '',
                fenxiaoname: ''
			},
			registerRules: {
				address: [{
					required: true,
					message: '请输入收货地址',
					trigger: 'blur'
				}],
				username: [{
					required: true,
					message: '请输入用户名',
					trigger: 'blur'
				}],
				password: [{
					required: true,
					validator: passwordValidata,
					trigger: 'blur'
				}],
				checkPass: [{
					required: true,
					validator: checkPassValidata,
					trigger: 'blur'
				}],
				captcha_code: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				}],
				nickname: [{
					required: true,
					message: '请输入单位名称',
					trigger: 'blur'
				}],
				realname: [{
					required: true,
					message: '请输入联系人',
					trigger: 'blur'
				}],
				mobile: [{
					required: true,
					message: '请输入联系电话',
					trigger: 'blur'
				}],
				activeselect: [{
					required: true,
					message: '请选择客户·类型',
					trigger: 'blur'
				}],
				full_address: [{
					required: true,
					message: '请输入详细地址',
					trigger: 'blur'
				}]
			},
			mobileRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "请输入短信动态码",
					trigger: "blur"
				}]
			},
			dynacodeData: {
				seconds: 120,
				timer: null,
				codeText: "获取动态码",
				isSend: false
			}, // 动态码
			ischecked: false,
			agreement: '',
			aggrementVisible: false,
			captcha: {
				// 验证码
				id: '',
				img: ''
			},
			registerConfig: {
				register: ''
			},
			activeName: "first", // tab切换
			reward: null,
			is_show: false,
			bgWidth: '',
			bgHeight: '',
			province: [],
			city: [],
			district: [],
			pickerValueArray: [],
			multiIndex: [0, 0, 0],
			isInitMultiArray: false,
			// 是否加载完默认地区
			isLoadDefaultAreas: true,
			loading: true,
			yes: true,
			inputseeklist: [],
			inputseekshow: false
		};
	},
	created() {
		this.getCode();
		this.regisiterAggrement();
		this.getRegisterConfig();
		this.getRegisterReward();
		this.bgWidth = document.documentElement.clientWidth + "px";
		this.bgHeight = document.documentElement.clientHeight + "px";
		this.getDefaultAreas(0, {
			level: 0
		})
		this.jingy();
	},
	computed: {
		pickedArr() {
			// 进行初始化
			if (this.isInitMultiArray) {
				return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
			}
			return [this.pickerValueArray[0], this.city, this.district]
		}
	},
	methods: {
		handleRemove(file) {
		},
		closeRewardPopup(type) {
			this.is_show = false;

			switch (type) {
				case 'point':
					this.$router.push('/member/my_point');
					break;
				case 'balance':
					this.$router.push('/member/account');
					break;
				case 'growth':
					this.$router.push('/member/qualification');
					break;
				case 'coupon':
					this.$router.push('/member/my_coupon');
					break;
				default:
					this.$router.push('/member/qualification');
					this.is_show = false;
			}
		},
		getRegisterReward() {
			getRegisterReward()
				.then(res => {
					if (res.code >= 0) {
						let data = res.data;
						if (data.is_use == 1 && (data.value.point > 0 || data.value.balance > 0 || data.value
							.growth > 0 || data.value.coupon_list.length > 0)) {
							this.reward = data.value;
						}
					}
				})
				.catch(err => {
				})
		},
		sendMobileCode(formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")

			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					return false
				}
			})
			this.$refs[formName].validateField("vercode", valid => {
				if (!valid) {
					registerMobileCode({
						mobile: this.registerForm.mobile,
						captcha_id: this.captcha.id,
						captcha_code: this.registerForm.vercode
					})
						.then(res => {
							if (res.code >= 0) {
								this.registerForm.key = res.data.key
								if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
									this.dynacodeData.timer = setInterval(() => {
										this.dynacodeData.seconds--
										this.dynacodeData.codeText = this.dynacodeData.seconds +
											"s后可重新获取"
									}, 1000)
								}
							}
						})
						.catch(err => {
							this.$message.error(err.message)
						})
				} else {
					return false
				}
			})
		},
		handleClick(tab, event) {
			if (this.activeName == "first") {
				this.loginMode = "account"
			} else {
				this.loginMode = "mobile"
			}
		},
		check() {
			this.ischecked = !this.ischecked;
		},
		toLogin() {
			this.$router.push('/login');
		},
		//  获取注册配置
		getRegisterConfig() {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value;
						if (this.registerConfig.register == '') {
							this.$message({
								message: '平台未启用注册',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push('/');
								}
							});
						}
					}
				})
				.catch(err => {
				});
		},
		//客户类型
		jingy() {
			registerlabellist().then(res => {
				const {
					count,
					list
				} = res.data
				this.registerForm.radioData = list

			})
		},
		sellectoption(value) {
			//判断默认选择的数据
			this.member_label = value.label_id;
			this.registerForm.member_label_name = value.label_name;
		},
		// 账号密码注册
		register() {
			this.$refs.registerRef.validate(valid => {
				if (valid) {
					if (!this.ischecked) {
						return this.$message({
							message: '请先阅读协议并勾选',
							type: 'warning'
						});
					}
					var data = {
						member_label: this.member_label, //企业类型
						username: this.registerForm.username.trim(), //登录账号
						password: this.registerForm.password, //密码
						realname: this.registerForm.realname, //联系人
						mobile: this.registerForm.mobile, //联系电话
						nickname: this.registerForm.nickname, //
						captcha_id: this.captcha_id,
						captcha_code: this.registerForm.captcha_code,
						province_id: this.registerForm.province_id, //省
						city_id: this.registerForm.city_id, //市
						district_id: this.registerForm.district_id, //区
						full_address: this.registerForm.full_address, //详细地址
						member_label_name: this.registerForm.member_label_name,
						parent_id: this.registerForm.parent_id,
						address:this.registerForm.address,
						inputfenxiao_name: this.registerForm.inputfenxiao_name,
						invitation_code: this.registerForm.invitation_code,
					};
   
				
					if (this.captcha.id != '') {
						data.captcha_id = this.captcha.id;
						data.captcha_code = this.registerForm.captcha_code;
					}
					this.$store.dispatch('member/register_token', data)
						.then(res => {
							if (res.code >= 0) {
								if (this.reward) {
									this.is_show = true;
								} else {
									this.$router.push('/member/qualification');

								}
								this.$confirm("注册已完成，但不能进行购物，请先上传资质信息，审核完成后可正常使用", '提示', {
									showClose: false
								})
							}
						})
						.catch(err => {
							this.$message.error(err?.message);
							this.getCode();
						});
				} else {
					return false;
				}
			});
		},
		// 手机号注册
		registerMobile() {
			this.$refs.mobileRuleForm.validate(valid => {
				if (valid) {
					if (!this.ischecked) {
						return this.$message({
							message: '请先阅读协议并勾选',
							type: 'warning'
						});
					}
					var data = {
						mobile: this.registerForm.mobile,
						key: this.registerForm.key,
						code: this.registerForm.dynacode
					};
					if (this.captcha.id != '') {
						data.captcha_id = this.captcha.id;
						data.captcha_code = this.registerForm.code;
					}
					this.$store
						.dispatch('member/registerMobile_token', data)
						.then(res => {
							if (res.code >= 0) {
								if (this.reward) {
									this.is_show = true;
								} else {
									this.$router.push('/member/qualification');
								}
							}
						})
						.catch(err => {
							this.$message.error(err.message);
							this.getCode();
						});
				} else {
					return false;
				}
			});
		},
		aggrementClose() {
			this.aggrementVisible = false;
		},
		// 获取协议
		regisiterAggrement() {
			getRegisiterAggrement()
				.then(res => {
					if (res.code >= 0) {
						this.agreement = res.data;
					}
				})
				.catch(err => {
				});
		},
		getAggrement() {
			this.aggrementVisible = true;
		},
		// 获取验证码
		getCode() {
			captcha({
				captcha_id: 'this.captcha.id'
			})
				.then(res => {
					if (res.code >= 0) {
						this.captcha = res.data;
						this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		/**
		 * 改变省
		 */
		changeProvice(id) {
			this.getAreas(id, data => (this.city = data))
			let obj = {}
			obj = this.province.find(item => {
				//这里的province就是上面遍历的数据源
				return item.id === id //筛选出匹配数据
			})
			this.registerForm.city_id = ""
			this.registerForm.district_id = ""
			this.registerForm.full_address = obj.name // 设置选中的地址
		},
		/**
		 * 改变市
		 */
		changeCity(id) {
			this.getAreas(id, data => (this.district = data))
			let obj = {}
			obj = this.city.find(item => {
				//这里的province就是上面遍历的数据源
				return item.id === id //筛选出匹配数据
			})
			this.registerForm.district_id = ""
			this.registerForm.full_address = this.registerForm.full_address + "-" + obj.name
		},
		/**
		 * 改变区
		 */
		changeDistrict(id) {
			let obj = {}
			obj = this.district.find(item => {
				//这里的province就是上面遍历的数据源
				return item.id === id //筛选出匹配数据
			})
			this.registerForm.full_address = this.registerForm.full_address + "-" + obj.name
		},

		// 异步获取地区
		getAreas(pid, callback) {
			getArea({
				pid: pid
			}).then(res => {

				if (res.code == 0) {
					var data = []
					res.data.forEach((item, index) => {
						data.push(item)
					})
					if (callback) callback(data)
				}
			})
				.catch(err => { })
		},
		/**
		 * 获取省市区列表
		 */
		getDefaultAreas(pid, obj) {
			getArea({
				pid: pid
			})
				.then(res => {
					if (res.code == 0) {
						var data = []
						var selected = undefined
						res.data.forEach((item, index) => {
							if (obj != undefined) {
								if (obj.level == 0 && obj.province_id != undefined) {
									selected = obj.province_id
								} else if (obj.level == 1 && obj.city_id != undefined) {
									selected = obj.city_id
								} else if (obj.level == 2 && obj.district_id != undefined) {
									selected = obj.district_id
								}
							}

							if (selected == undefined && index == 0) {
								selected = item.id
							}
							data.push(item)
						})

						this.pickerValueArray[obj.level] = data
						if (obj.level + 1 < 3) {
							obj.level++
							this.getDefaultAreas(selected, obj)
						} else {
							this.isInitMultiArray = true
							this.isLoadDefaultAreas = true
						}

						this.province = this.pickerValueArray[0]
					}
					this.loading = false
				})
				.catch(err => {
					this.loading = false
				})
		},
		/**
		 * 渲染默认值
		 */
		//获取上级销售
		fenxiaonamechange(query) {
			//获取select输入框数据query
			if (query !== '') {
				getFenxiaoname({
					key:query
				}).then(res =>{

					// this.inputseeklist = res.filter(e =>e.username.indexOf(query) != -1);
                    this.inputseeklist = res

                    if (this.inputseeklist.length == 0){
                        this.registerForm.inputfenxiao_name = query
                    }
				}).catch(err =>{
				})
			}else{
				//重置数组
				this.inputseeklist = [];
			}
		},
        //     fenxiaonamechange(){
        //         this.inputseekshow = true;
        //         getFenxiaoname({
        //             key:this.registerForm.inputfenxiao_name
        //         }).then(res =>{
        //             this.inputseeklist = res.filter(e =>e.username.indexOf(this.registerForm.inputfenxiao_name) != -1);
        //         })
        // },
		cityChange(item){
			this.registerForm.inputfenxiao_name = item.username;
			this.registerForm.parent_id=item.salesman_id;
            this.registerForm.fenxiaoname = item.nickname;
            this.inputseekshow = false
		}
	}
};
</script>
<style lang="scss" scoped>
//aaa
.avatar-uploader .el-upload {
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
	border: 1px dashed #d9d9d9;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}

//aaa

.register {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
}

.box-card {
	width: 1000px;
	margin: 0 auto;
	display: flex;
	background-color: #ffffff;
	border: 1px solid #ddd;
	padding: 0 30px 30px 30px;
	flex-direction: column;
	border-radius: 15px;
	padding-bottom: 25px;

	.register-title {
		border-bottom: 1px solid #f1f1f1;
		text-align: left;
		margin-bottom: 20px;
		font-size: 16px;
		color: $base-color;
		padding: 10px 0;
	}

	.register-account {
		width: 100%;
		text-align: center;
	}

	.code {
		width: 80%;
		text-align: left;
	}

	.el-form {
		margin: 0 30px;

		.captcha {
			vertical-align: top;
			max-width: inherit;
			max-height: 38px;
			line-height: 38px;
			cursor: pointer;
		}
	}

	.xyContent {
		height: 600px;
		overflow-y: scroll;
	}

	.xy {
		margin-left: 110px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		margin-right: 30px;

		.toLogin {
			cursor: pointer;
		}

		.xy-wrap {
			display: flex;
			align-items: center;
			font-size: $ns-font-size-base;
			cursor: pointer;

			.iconfont {
				display: flex;
				align-content: center;
			}

			.content {
				margin-left: 3px;

				b {
					color: $base-color;
				}
			}
		}

		.icon-xuanze-duoxuan {
			color: $base-color;
		}
	}

	.el-button {
		margin: 20px 0 0 25px;
		background-color: $base-color;
		color: #ffffff;
		width: calc(100% - 60px);
	}

	.el-cascader {
		widht: 100%;
	}
}

.floatLayer-wrap {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 999;
	position: absolute;

	.reward-wrap {
		width: 400px;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.bg-img {
			width: 100%;
			will-change: transform;
		}

		.bg-img-head {
			position: absolute;
			top: -90px;
			width: 100%;

		}

		.bg-img-money {
			position: absolute;
			width: 100%;
			left: -20px;
			top: 80px;
			z-index: 10;
		}

		.wrap {
			width: calc(100% - 1px);
			height: 100%;
			background-color: #ef3030;
			margin-top: -40px;
			padding-bottom: 30px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;

			&>div {
				position: relative;
			}
		}

		.reward-content {
			margin: 0 25px 0 25px;
		}

		.reward-item {
			.head {
				color: #fff;
				text-align: center;
				line-height: 1;
				margin: 10px 0;
			}

			.content {
				display: flex;
				padding: 8px 13px;
				background: #fff;
				border-radius: 5px;
				margin-bottom: 5px;

				.info {
					flex: 1;
				}

				.tip {
					color: #ff222d;
					padding: 5px 0 5px 15px;
					width: 70px;
					line-height: 1.5;
					letter-spacing: 1px;
					border-left: 1px dashed #e5e5e5;
					height: 40px;
					line-height: 40px;
				}

				.num {
					font-size: 26px;
					color: #ff222d;
					font-weight: bolder;
					line-height: 1;
				}

				.coupon-name {
					font-size: 19px;
				}

				.type {
					font-size: $ns-font-size-base;
					margin-left: 5px;
					line-height: 1;
				}

				.desc {
					margin-top: 4px;
					color: $base-color;
					font-size: $ns-font-size-base;
					line-height: 1;
				}
			}
		}

		.btn {
			position: absolute;
			width: calc(100% - 50px);
			bottom: 20px;
			left: 25px;

			.btn-img {
				width: 100%;
			}
		}
	}
}

.close-btn {
	text-align: center;
	margin-top: 20px;

	.iconfont {
		color: #fff;
		font-size: 20px;
	}
}

.clear {
	content: '';
	display: block;
	clear: both;
}

.el-select {
	margin-right: 10px;

}

.inputseek {
	position: absolute;
	left: 0;
	top: 61rpx;
	background: #fff;
	border: 1px solid #f1f1f1;
	width: 100%;
	z-index: 3;

	.insek_list {
		z-index: 99999;
		padding: 5px 10rpx;
		margin-bottom: 10rpx;
		display: flex;
	}
}
</style>
